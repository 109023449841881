/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Handle mobile navigation visibility and nav toggle state
        $('.c-toggle--nav').click(function(e) {
            e.preventDefault();

            $('.c-header').toggleClass('is-toggled');
            $(this).toggleClass('is-active');

            $('.c-nav').slideToggle();
        });

        // Handle dropdown toggle visibility
        $('.c-toggle--dropdown').click(function() {
            $('.c-header').toggleClass('is-toggled');

            $(this).parent('.has-dropdown').toggleClass('is-toggled').siblings('li').removeClass('is-toggled');
        });


        $('.aircraft-gallery').slick({
          dots: true,
          arrows: false,
          infinite: true,
          autoplay: true,
          speed: 3000,
          pauseOnHover: true,
          slidesToShow: 1,
          adaptiveHeight: true,
        });

        $('.aircraft-gallery-nav').slick({
          dots: false,
          arrows: false,
          infinite: true,
          speed: 300,
          slidesToShow: 10,
          adaptiveHeight: true,
          asNavFor: '.aircraft-gallery',
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            }
          ]
        });

        // Handle header background color on scroll
        $(window).scroll(function() {
            var scrollX     = $(this).scrollTop(),
                elHeader    = $('.c-header');

            if (scrollX > 10) {
                $(elHeader).addClass('is-scrolling');
            } else {
                $(elHeader).removeClass('is-scrolling');
            }
        });

        // Handle hero background image
        var elHero      = $('.c-hero'),
            heroMobile  = $(elHero).data('mobile'),
            heroDesktop = $(elHero).data('desktop');

        if (viewportWidth < 1024) {
            $(elHero).css('background-image', 'url('+heroMobile+')');
        } else {
            $(elHero).css('background-image', 'url('+heroDesktop+')');
        }

        // Handle content background image
        var elContent = $('.c-content--background');

        $(elContent).each(function() {
            var backgroundMobile    = $(this).data('mobile'),
                backgroundDesktop   = $(this).data('desktop');

            if (viewportWidth < 1024) {
                $(this).css('background-image', 'url('+backgroundMobile+')');
            } else {
                $(this).css('background-image', 'url('+backgroundDesktop+')');
            }
        });

        // Handle column background image
        var elColumn = $('.o-col--background');

        $(elColumn).each(function() {
            var columnMobile    = $(this).data('mobile'),
                columnDesktop   = $(this).data('desktop');

            if (viewportWidth < 1024) {
                $(this).css('background-image', 'url('+columnMobile+')');
            } else {
                $(this).css('background-image', 'url('+columnDesktop+')');
            }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
